import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropType from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import slugify from 'react-slugify';
import ElementPortableText from 'src/components/ElementPortableText/ElementPortableText';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import imgFallback from 'src/utils/image';

// eslint-disable-next-line react/prop-types
function ServicePage({ location, data: { service } }) {
  const slugs = service.serviceBodySections.map((section) => slugify(section.title));
  const seo = {
    title: service.pageSeo?.title ?? service.title,
    description: service.pageSeo?.description ?? service.description,
    image: service.pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-node-page px-0 pt-7">
        <Container className="py-6">
          <Row className="pt-6">
            <Col xs={12} lg={{ span: 5, offset: 2 }}>
              <h1 className="h2">{service.title}</h1>
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 3 }} className="text-muted py-6">
              {service.intro}
            </Col>
            <Col xs={12} className="my-lg-6 my-3 ">
              <GatsbyImage
                alt={service.banner.alt ?? ''}
                image={service.banner?.image?.asset?.gatsbyImageData ?? imgFallback}
                className="d-none d-sm-flex"
              />
              <GatsbyImage
                alt={service.bannerMobile.alt ?? ''}
                image={service.bannerMobile?.image?.asset?.gatsbyImageData ?? imgFallback}
                className="d-sm-none"
              />
            </Col>
          </Row>
          <Row className="py-6">
            <Col className="d-none d-lg-block" lg={3}>
              <Scrollspy
                offset={-100}
                className="scrollspy-nav list-unstyled align-self-lg-start"
                items={slugs}
                currentClassName="is-current"
              >
                {service.serviceBodySections.map((bodySection) => (
                  <li className="scrollspy-nav-item" key={bodySection._key}>
                    <AnchorLink
                      to={`${location.pathname}#${slugify(bodySection.title)}`}
                      title={bodySection.title}
                      className="scrollspy-nav-link btn-link-primary"
                    />
                  </li>
                ))}
              </Scrollspy>
            </Col>
            <Col xs={12} lg={9} className="mt-xl-n7">
              {service.serviceBodySections.map((bodySection) => (
                <Col
                  key={bodySection._key}
                  xs={12}
                  className={`mt-lg-5 mt-xl-7 type--${slugify(bodySection.title)}`}
                  id={slugify(bodySection.title)}
                >
                  <h3 className="mb-3">{bodySection.title}</h3>
                  {/* <Col xs={12} className="ck-editor-text px-0" dangerouslySetInnerHTML={{ __html: e.chp_text.value }} /> */}
                  <ElementPortableText content={bodySection._rawBody} />
                </Col>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    service: sanityService(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      banner {
        alt
        image {
          asset {
            gatsbyImageData(aspectRatio: 3, layout: FULL_WIDTH)
          }
        }
      }
      bannerMobile: banner {
        alt
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.5, width: 920)
          }
        }
      }
      intro
      description
      previewImage {
        alt
        image {
          asset {
            gatsbyImageData(width: 300, height: 260, placeholder: BLURRED)
          }
        }
      }
      serviceBodySections {
        _key
        title
        _rawBody
      }
    }
  }
`;

ServicePage.propTypes = {
  data: PropType.shape({
    service: PropType.shape({}),
  }).isRequired,
};

export default ServicePage;
